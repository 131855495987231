import * as Yup from "yup";

export const schema = Yup.object().shape({
    old_password: Yup.string()
      .required("Passwort erforderlich."),
    new_password: Yup.string()
      .required("Passwort erforderlich.")
      .test('', 'Ihr neues Passwort darf nicht mit dem alten übereinstimmen',  function(value){
        return this.parent.old_password !== value
      })
      .min(8, "Ihr Passwort muss aus mindestens 8 Zeichen bestehen.")
      .max(40, "Das Passwort darf 40 Zeichen nicht überschreiten."),
    new_password_confirmation: Yup.string()
      .required("Passwort bestätigen ist erforderlich.")
      .oneOf([Yup.ref("new_password"), null], "Passwort und Bestätigung stimmen nicht überein."),
    acceptTerms: Yup.bool().oneOf([true], "Bedingungen akzeptieren ist erforderlich."),
  })