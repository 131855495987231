import { FunctionComponent } from "react";
import { Section } from "@kickstartds/base/lib/section";
import { Icon } from "@kickstartds/base/lib/icon";
import ContactPersonDefault from "./ContactPersonDefault";
import { ContactPersonDefaultProps } from "./ContactPersonDefaultProps";
import { ContactPersonsSectionProps } from "../ContactPerson/ContactPersonProps";
import "./ContactPerson.scss";

interface ContactPersonProps {
  apiData: any;
}

const ContactPerson: FunctionComponent<ContactPersonProps> = ({ apiData }) => {
  return (
    <Section {...ContactPersonsSectionProps}>
      <div id="contact-person-container" className="contact-person__container">
        <h3 className="contact-person__headline">
          Ihre individuellen Ansprechpartner
        </h3>
        <ContactPersonDefault {...ContactPersonDefaultProps} />
        {apiData?.account_managers?.map((contactPerson: any, index: number) => {
          return (
            <div key={index}>
              {contactPerson ? (
                <div className="contact-person__information" key={index}>
                  <div className="contact-person__image">
                    <img
                      src={`data:${contactPerson?.image.mime_type};base64,${contactPerson?.image.binary}`}
                      alt="contact person name"
                    />
                  </div>
                  <div className="contact-person__info">
                    <div>
                      <p className="contact-person__name">
                        {contactPerson?.name}
                      </p>
                      {contactPerson?.position_description ? (
                        <p className="contact-person__person-description">
                          {contactPerson?.position_description}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="contact-person__telephone">
                      <a
                        className={contactPerson?.availability ? '' : 'phone-margin'}
                        href={`tel:${contactPerson?.phone_number}`}
                      >
                        <Icon icon="phone" />
                        {contactPerson?.phone_number_formatted}
                      </a>
                      {contactPerson?.availability ? (
                        <p className="contact-person__availability">
                          {contactPerson?.availability}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="contact-person__email">
                      <a href={`mailto:${contactPerson?.email}`}>
                        <Icon icon="email" />
                        {contactPerson?.email}
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default ContactPerson;
export type { ContactPersonProps };
