import axios from "axios";

export default class RailsClient {
  railsUrl: string | undefined;
  client: any;

  constructor() {
    this.railsUrl = process.env.REACT_APP_RAILS_BASEURL;
    this.init();
  }

  init = () => {
    let headers = {
      Accept: "application/json",
    };

    this.client = axios.create({
      baseURL: this.railsUrl,
      timeout: 31000,
      headers: headers,
    });

    // Define interceptor for checking session expiration
    this.client.interceptors.request.use(
      async (config) => {
        const _client = axios.create({
          baseURL: process.env.REACT_APP_RAILS_BASEURL,
          timeout: 31000,
          headers: {
            Accept: "application/json",
          },
        });
        const sessionValidity = await _client.get("/session.json", {withCredentials: true})

        if (sessionValidity.data.success) {

          return config;
        } else {
          window.location.href = `${process.env.REACT_APP_RAILS_BASEURL!}/session/new`

          return null;
        }
      },
      function(error){

      }
    )
  };

  getUserJumps = async () => {
    try {
      return await this.client.get("/portal_permissions.json", {withCredentials: true});
    } catch (error) {
      console.error(error)
    }
    // return this.init().get("/list", { params: params });
  }

  getAcountManagers = async () => {
    try {
      return await this.client.get("/account_managers.json", {withCredentials: true})
    } catch (error) {
      console.error(error)
    }
  }

  changePassword = async (values) => {
    try {
      let body = JSON.stringify(values);

      return await this.client.patch("/password_change.json", body, {withCredentials: true})
    } catch (error) {

      console.error(error)
    }
  }

  checkSession = async () => {
    try {
      return await this.client.get("/session.json", {withCredentials: true})
    } catch (error) {
      console.error(error)
    }
  }
};
