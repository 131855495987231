import { FunctionComponent, useState } from "react";
import { Icon } from "@kickstartds/base/lib/icon" 
import { Form, Formik } from "formik";
import {schema} from "./FormValidationShema"
import TextFieldForm from "./TextFieldForm";
import { Button, ButtonProps } from "@kickstartds/base/lib/button";
import ModalActions from "../Modal/ModalActions";
import RailsClient from "../../rails/RailsClient";

interface ChangePasswordFormProps {
  closeModal: () => void,
  oldPassword: any,
  newPassword: any,
  confirmPassword: any
}

const ChangePasswordForm: FunctionComponent<ChangePasswordFormProps> = ({
  closeModal,
  oldPassword,
  newPassword,
  confirmPassword
}) => {
  const [backendSuccess, setBackendSuccess] = useState(false)
  const [notice, setNotice] = useState("")
  const closeModalPropsButton: ButtonProps = {
    label: "OK",
    variant: "outline",
    size: "medium",
  }
  const railsClient = new RailsClient()

  return (
    <Formik
      initialValues={{
        old_password: "",
        new_password: "",
        new_password_confirmation: "",
      }}
      validationSchema={schema}

      //TODO Move to railsClient class.
      onSubmit={async (values) => {
        await railsClient.changePassword(values)
          .then((response) => {
            if (response.data.success) {
              setBackendSuccess(true);
              setNotice(response.data.notice);
            } else {
              setBackendSuccess(false);
              setNotice(response.data.notice);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          {backendSuccess ? (
            <>
              <p className="modal__success-message">{notice}</p>
              <div className="modal__actions">
                <Button {...closeModalPropsButton} onClick={closeModal} />
              </div>
            </>
          ) : (
            <>
              {!backendSuccess && notice !== "" ? (
                <p className="modal__error-message">
                  <Icon icon="info" />
                  {notice}
                </p>
              ) : (
                ""
              )}
              <TextFieldForm {...oldPassword} />
              <TextFieldForm {...newPassword} />
              <TextFieldForm {...confirmPassword} />
              <ModalActions
                closeModal={closeModal}
                submitting={formik.isSubmitting}
              />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default ChangePasswordForm;
