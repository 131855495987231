import { FunctionComponent } from "react";
import { Icon } from "@kickstartds/base/lib/icon";
import "./ContactPerson.scss";

interface ContactPersonDefaultProps {
  name: string,
  image: string,
  phone_number: string,
  phone_number_formatted: string,
  availability: string,
  email: string
}

const ContactPersonDefault: FunctionComponent<ContactPersonDefaultProps> = ({
  name, 
  image,
  phone_number,
  phone_number_formatted,
  availability,
  email
}) => {

  return (
  <div>
    <div className="contact-person__information">
      <div className="contact-person__image">
        <img src={image} alt="contact person name" />
      </div>
      <div className="contact-person__info">
        <div>
          <p className="contact-person__name">{name}</p>
        </div>
        <div className="contact-person__telephone">
          <a href={`tel:${phone_number}`}>
            <Icon icon="phone" />
            {phone_number_formatted}
          </a>
          <p className="contact-person__availability">{availability}</p>
        </div>
        <div className="contact-person__email">
          <a href="mailto:geschaeftskunden@badenova.de">
            <Icon icon="email" />
            {email}
          </a>
        </div>
      </div>
    </div>
  </div>
  );
};

export default ContactPersonDefault;
export type { ContactPersonDefaultProps };
