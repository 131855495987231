import { FunctionComponent } from "react";
import { Icon } from "@kickstartds/base/lib/icon";
import "./Modal.scss";

interface ModalProps {
    modalIsOpen: boolean;
    closeModal: () => void;
    children: React.ReactNode;
}

const Modal: FunctionComponent<ModalProps> = ({
    modalIsOpen,
    closeModal,
    children,
}) => {
    return (
        <>
            <div id="modal" className={`modal ${modalIsOpen ? "modal__show" : "modal__hide"}`} onClick={closeModal}/>
            <div className={`modal__content ${modalIsOpen ? "modal__show" : "modal__hide"}`}>
                <div className="modal__header">
                    <h2 className="modal__title">Password ändern</h2>
                    <Icon className="icon-close" icon="close" onClick={closeModal} />
                </div>
                <div className="modal__body">
                    {children}
                </div>
            </div>
        </>
    );
};

export default Modal;
