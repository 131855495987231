import { FunctionComponent } from "react";
import { Button } from "@kickstartds/base/lib/button";
import { nextButtonProps, closeButtonProps } from "./ModalActionsProps";

interface ModalActionsProps {
    closeModal: () => void
    submitting: boolean
}

const ModalActions: FunctionComponent<ModalActionsProps> = ({
    closeModal,
    submitting
}) => {
    return (
        <>
            <div className="modal__actions">
                <Button {...nextButtonProps} disabled={submitting}/>
                <Button {...closeButtonProps} onClick={closeModal}/>
            </div>
        </>
    );
};

export default ModalActions;
