import { ButtonProps } from "@kickstartds/base/lib/button"

export const nextButtonProps: ButtonProps = {
    label: "WEITER",
    variant: "outline",
    size: "medium",
    type: "submit"
}

export const closeButtonProps: ButtonProps = {
    label: "ABBRECHEN",
    variant: "clear",
    size: "medium"
}