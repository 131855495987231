import { FunctionComponent, useState } from "react";
import { Button } from "@kickstartds/base/lib/button"
import { changePasswordButtonProps, logoutButtonProps } from "./HeaderFooterButtonProps";
import { oldPassword, newPassword, confirmPassword } from "../ChangePasswordForm/ChangePasswordFormProps";
import Modal from "../Modal/Modal";
import ChangePasswordForm from "../ChangePasswordForm/ChangePasswordForm";
import RailsClient from "../../rails/RailsClient";
import "./HeaderFooter.scss";

interface HeaderFooterProps {
}

const HeaderFooter: FunctionComponent<HeaderFooterProps> = ({ children }) => {

  const railsClient = new RailsClient()
  const [isOpen, setIsOpen] = useState(false)

  const year = new Date().getFullYear().toString()

  const logout = () => {
    window.location.href = `${process.env.REACT_APP_RAILS_BASEURL}/logout`
  }

  const sessionCheck = () => {
    railsClient.checkSession().then((res) => {
      if (res?.data?.success) {
        setIsOpen(true)
      } else {
        return null
      }
    })
  }

  return (
    <>
      <header className="header">
        <div className="header__main">
          <div className="header__row">
            <div className="header__logo">
              <a href="https://www.badenova.de/geschaeftskunden/" target="_blank" rel="noreferrer">
                <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="badenova AG & Co. KG" className="logo" />
              </a>
            </div>
            <div className="header__features">
              <div className="header__change-password">
                <Button onClick={()=> {sessionCheck()}} {...changePasswordButtonProps} />
              </div>
              <div className="header__logout">
                <Button onClick={logout} {...logoutButtonProps} />
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Modal modalIsOpen={isOpen} closeModal={()=> {setIsOpen(false)}}>
        <ChangePasswordForm
          closeModal={()=> {setIsOpen(false)}}
          oldPassword={oldPassword}
          newPassword={newPassword}
          confirmPassword={confirmPassword}
        />
      </Modal>
      {children}
      <footer className="footer">
        <div className="footer__row">
          <div className="footer__legals">
            <ul>
              <li>
                <a href="https://www.badenova.de/sonderseiten/rechtliche-hinweise" title="Impressum" target="_blank" rel='noopener noreferrer'>Impressum</a>
              </li>
              <li>
                <a href="https://www.badenova.de/sonderseiten/rechtliche-hinweise/datenschutz.jsp" title="Datenschutz" target="_blank" rel='noopener noreferrer'>Datenschutz</a>
              </li>
            </ul>
          </div>
          <div className="footer__publisher">
            <div>© <span className="footer__publisher-year">{year}</span> badenova AG & Co. KG</div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default HeaderFooter;
export type { HeaderFooterProps };
