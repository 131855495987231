import { FunctionComponent } from "react";
import PageContent from "../PageContent/PageContent";
import HeaderFooter from "../HeaderFooter/HeaderFooter";

import "./PageLayout.scss"

interface PageLayoutProps {
  userJumps: any,
  accountManagers: any
  session: any
}

const PageLayout: FunctionComponent<PageLayoutProps> = ({session, userJumps, accountManagers}) => {
  if (session) {
    return (
      <div className="page-layout">
        <HeaderFooter>
          <PageContent userJumps={userJumps} accountManagers={accountManagers}/>
        </HeaderFooter>
      </div>
    )
  }
  else if (session === undefined) {
    return <></>
  }
  else {
    window.location.href = `${process.env.REACT_APP_RAILS_BASEURL!}/session/new`

    return null
  }
}

export default PageLayout;
export type { PageLayoutProps };
