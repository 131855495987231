import { ButtonProps } from "@kickstartds/base/lib/button"

export const logoutButtonProps: ButtonProps = {
    label: "Kundenlogout",
    variant: "outline",
    size: "small",
    iconBefore: true,
    icon: {
        icon: "logout",
    }
}

export const changePasswordButtonProps: ButtonProps = {
    label: "Passwort ändern",
    variant: "clear",
    size: "small"
}