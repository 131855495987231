import { TextFieldProps } from "@kickstartds/form"

export const oldPassword: TextFieldProps = {
    label: "Aktuelles Passwort",
    name: "old_password",
    type: "password"
}

export const newPassword: TextFieldProps = {
    label: "Neues Passwort",
    name: "new_password",
    type: "password",
    hint: "Passwortlänge mind. 8 Zeichen"
}

export const confirmPassword: TextFieldProps = {
    label: "Neues Passwort wiederholen",
    name: "new_password_confirmation",
    type: "password"
}