import { FunctionComponent, useEffect, useState } from "react";
import PageLayout from './components/PageLayout/PageLayout';
import IconSprite from "./components/IconSprite/IconSprite";
import RailsClient from "./rails/RailsClient";

import "@kickstartds/base/lib/global/base.js"
import './App.scss';

const App: FunctionComponent = () => {
  const railsClient = new RailsClient()
  const [session, setSession] = useState<undefined|boolean>(undefined)
  const [userJumps, setUserJumps] = useState({})
  const [accountManagers, setAccountManagers] = useState({})

  useEffect(() => {
    railsClient.checkSession().then((res) => {
      if (res?.data?.success) {
        setSession(res?.data?.success)

        railsClient.getUserJumps().then((res) => {
          setUserJumps(res?.data)
        })

        railsClient.getAcountManagers().then((res) => {
          setAccountManagers(res?.data)
        })
      }
      else {
        setSession(false)
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <IconSprite />
      <PageLayout session={session} userJumps={userJumps} accountManagers={accountManagers}/>
    </>
  );
}

export default App;
