import { FunctionComponent } from "react";
import { FieldHookConfig, useField } from "formik";
import { TextField } from "@kickstartds/form";

interface TextFieldFormProps {
  label: string,
  name: string,
  type: string,
  hint?: string
}


const TextFieldForm: FunctionComponent<any> = (props: TextFieldFormProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(props);

  return (
    <>
      <TextField 
        {...field}
        type={props.type}
        name={props.name}
        label={props.label}
        invalid={meta.error ? true : false}
        invalidMessage={meta.error && meta.touched && meta.error}
        hint={props.hint}
      />
    </>
    );
};

export default TextFieldForm;