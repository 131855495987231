import { TeaserBoxProps } from "@kickstartds/base/lib/teaser-box"
import { SectionProps } from "@kickstartds/base/lib/section"
import { StorytellingProps } from "@kickstartds/content/lib/storytelling"

export const storytellingSectionProps: SectionProps = {
  background: "accent",
  width: "wide",
  spaceAfter: "default"
}

export const storytellingProps: StorytellingProps = {
  general: {
    full: true
  },
  image: {
    source: `${process.env.PUBLIC_URL}/BN-GKP-Stecker.jpg`,
    hAlign: "right",
    vAlign: "top",
    order: {
      desktopImageLast: true
    }
  },
  box: {
    headline: {
      level: "h1",
      content: "Willkommen im Kundenportal",
      align: "left",
      spaceAfter: "none"
    },
    text: "In unserem neuen Kundenportal für badenova Geschäftkunden finden Sie einen direkten Zugang zu allen Tools für Ihr Unternehmen und die entsprechenden Kontaktdaten Ihrer Anpsprechpartner.",
    link: {
      label: "Jetzt entdecken",
      variant: "clear",
      size: "medium",
      href: "#contact-person-container",
      iconBefore: true,
      icon: {
        icon: "arrow-down"
      }
    }
  },
  backgroundColor: "#fff"
}

export const teaserBoxSectionProps: SectionProps = {
  spaceBefore: "none",
  spaceAfter: "small",
  background: "default",
  mode: "tile",
  gutter: "large",
  width: "wide",
}

export const transformToTeaserBoxProps = (userData: any): TeaserBoxProps => {
  if (userData?.name === "greenpocket") {
    return {
      topic: "Kundenportal",
      text: "Falls Ihnen von badenova noch kein Zugang zum Kundenportal eingerichtet wurde, kontaktieren Sie bitte Ihren Ansprechpartner.",
      ratio: "16:9",
      link: {
        newTab: true,
        label: "TOOL ÖFFNEN",
        variant: "solid",
        size: "medium",
        href: userData?.link,
      },
    };
  } else if (userData?.name === "tranchentool") {
    return {
      topic: "Tranchentool",
      text: "Bewirtschaftung von Tranchenverträgen",
      ratio: "16:9",
      link: {
        newTab: true,
        label: "TOOL ÖFFNEN",
        variant: "solid",
        size: "medium",
        href: userData?.link,
      },
    };
  } else if (userData?.name === "envago") {
    return {
      topic: "Envago",
      text: "Some Envago text",
      ratio: "16:9",
      link: {
        newTab: true,
        label: "TOOL ÖFFNEN",
        variant: "solid",
        size: "medium",
        href: userData?.link,
      },
    };
  } else {
    return {
      topic: "",
      text: "",
      ratio: "16:9",
      link: {
        label: "",
        variant: "solid",
        size: "medium",
        href: "",
      },
    };
  }
};
