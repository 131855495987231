import { FunctionComponent } from "react";
import { Section } from '@kickstartds/base/lib/section'
import { TeaserBox } from '@kickstartds/base/lib/teaser-box'
import { Storytelling } from '@kickstartds/content/lib/storytelling'
import { 
  storytellingSectionProps, 
  storytellingProps, 
  teaserBoxSectionProps,
  transformToTeaserBoxProps
} from './IntroSectionProps'
import "./IntroSection.scss"

interface IntroSectionProps {
  apiData: any
}

const IntroSection: FunctionComponent<IntroSectionProps> = ({apiData}) => {
  const userData = apiData
  
  return (
    <div className="intro-section">
      <Section {...storytellingSectionProps}>
        <Storytelling {...storytellingProps} className={userData?.portal_permissions?.length ? "c-storytelling-margin-button" : ""} />
      </Section>
      {
        userData?.portal_permissions?.length ?
          <Section {...teaserBoxSectionProps}>
            {
              userData?.portal_permissions?.map((item: any, index: number) => {
                return <TeaserBox key={index} {...transformToTeaserBoxProps(item)} />
              })
            }
          </Section>
        : 
        <></>
      }
    </div>
  )
}

export default IntroSection;
