import { FunctionComponent} from "react";
import IntroSection from "../IntroSection/IntroSection";
import ContactPerson from "../ContactPerson/ContactPerson";
import "./PageContent.scss"

interface PageContentProps {
  userJumps: any,
  accountManagers: any
}

const PageContent: FunctionComponent<PageContentProps> = ({userJumps, accountManagers}) => {

  return (
    <div className="page-content">
      <IntroSection apiData={userJumps}/>
      <ContactPerson apiData={accountManagers} />
    </div>
  )
}

export default PageContent;
export type { PageContentProps };
